import React,{useRef} from 'react';
import { HomeChart } from '../../Components/Chart';
import '../../Assets/CSS/Horapa.css';
import {DataTable} from '../../Components'
import { FaRegUser,FaInfo  } from "react-icons/fa";
import { MdKeyboardReturn } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const PageQuotationCreate =()=> {
    const RedirectTo = useNavigate()

    const ref = useRef();
    const _configs = {
        "order": [[3, "desc"]],
        pageLength: 10
    }

    const _BackStep = ()=>{
        RedirectTo(-1);
    }
    return (
        <>
        <React.Fragment>
            
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-8'>
                        <div className='card'>
                            <div className='card-header'>
                                <h5 className='fw-bold'>สร้างใบเสนอราคา</h5>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>เลขที่ใบเสนอราคา :</span>
                                    </div>
                                    <div className='col-10'>
                                        <span className=''>-</span>
                                    </div>
                                </div>
                            
                                <div className='row mt-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>สถานะ :</span>
                                    </div>
                                    <div className='col-10'>
                                        <span className="badge bg-info">สร้างใบเสนอราคา</span>
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>บริการ :</span>
                                    </div>
                                    <div className='col-4'>
                                        <select className='form-control'>
                                            <option value={0}>เลือกบริการ / แพ็คแกจ</option>
                                            <option value={0}>[BHA1] พิธีสงฆ์ ชุดศิริสุข A</option>
                                            <option value={0}>[BHC1] พิธีสงฆ์ ชุดศิริสุข C</option>
                                        </select>
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>จำนวน :</span>
                                    </div>
                                    <div className='col-2'>
                                        <input type='text' className='form-control text-center'/>
                                    </div>
                                    <div className='col-2'>
                                    <span className=''>ท่าน</span>
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>ประเภทงาน :</span>
                                    </div>
                                    <div className='col-4'>
                                    <select className='form-control'>
                                            <option value={0}>ทำบุญบ้าน</option>
                                            <option value={0}>ทำบุญบริษัท</option>
                                            <option value={0}>อาหารกลางวัน</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>สถานที่จัดงาน :</span>
                                    </div>
                                    <div className='col-10'>
                                        <textarea className='form-control' defaultValue={'เลขที่ 791/16 ตำบลพยอม อำเภอวังน้อย จังหวัดพระนครศรีอยุธยา'}></textarea>
                                        <span className=''></span>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>ช่วงเวลา :</span>
                                    </div>
                                    <div className='col-10'>
                                        <span className=''> 10/06/2567 เวลา 10.30</span>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>นัดเตรียมสถานที่ :</span>
                                    </div>
                                    <div className='col-10'>
                                    <span className=''> 10/06/2567 เวลา 07.30</span>
                                    </div>
                                </div>
                                

                                <div className='row mt-5'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>หมายเหตุ :</span>
                                    </div>
                                    <div className='col-10'>
                                        <textarea className='form-control' style={{'minHeight':'100px'}}/>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-2 '>
                                    </div>
                                    <div className='col-10 text-end'>
                                        
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-12 text-center'>
                                                <div className='btn-group btn-group-lg'>
                                                    <button type='button' className='btn btn-lg btn-save'>บันทึก</button>
                                                    <button type='button' className='btn bnt-lg btn-success' style={{'minWidth':'120px'}}>ข้อมูลลูกค้า</button>
                                                    <button type='button' className='btn bnt-lg btn-warning text-white' style={{'minWidth':'120px'}}>ประวัติการสั่งซื้อ</button>
                                                    <button type='button' className='btn bnt-lg btn-danger text-white' onClick={()=>{_BackStep();}}><MdKeyboardReturn /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-lg-12'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <h5 className='fw-bold'>ข้อมูลลูกค้า</h5>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-3 text-end'>
                                                <span className='fw-bold'>รหัสลูกค้า :</span>
                                            </div>
                                            <div className='col-9'>
                                                <span className=''>00123411</span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-3 text-end'>
                                                <span className='fw-bold'>ชื่อลูกค้า :</span>
                                            </div>
                                            <div className='col-9'>
                                                <span className=''>คุณทดสอบ ทั่วไป</span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-3 text-end'>
                                                <span className='fw-bold'>เบอร์โทรติดต่อ :</span>
                                            </div>
                                            <div className='col-9'>
                                                <span className=''>0123456789</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className='col-lg-12'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <h5 className='fw-bold'>ประวัติการสั่งซื้อ</h5>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-12 table-responsive'>
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center'>วันที่</th>
                                                            <th className='text-center'>เลขที่ใบเสนอราคา</th>
                                                            <th className='text-center'>สถานะ </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-8'>
                        <div className='card'>
                            <div className='card-header'>
                                <h5 className='fw-bold'>รายการเสนอราคา</h5>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12 table-responsive'>
                                        <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-center'>รายการ</th>
                                                        <th className='text-center'>จำนวน</th>
                                                        <th className='text-center'>หมายเหตุ</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td className='text-left'></td>
                                                    <td className='text-left'></td>
                                                    <td className='text-left'></td>

                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='card'>
                            <div className='card-header'>
                                <h5 className='fw-bold'>รายละเอียดอุปกรณ์</h5>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12 table-responsive'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>อุปกรณ์</th>
                                                    <th className='text-center'>จำนวน</th>
                                                    <th className='text-center'>ราคา</th>
                                                    <th className='text-center'>หมายเหตุ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        </>
    )
}

export default React.memo(PageQuotationCreate)
