import React,{useRef} from 'react';
import { HomeChart } from '../../Components/Chart';
import '../../Assets/CSS/Horapa.css';
import {DataTable} from '../../Components'
import { FaRegUser,FaInfo  } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const Customer =()=> {
    const ref = useRef();
    const RedirectTo = useNavigate()

    const _configs = {
        "order": [[3, "desc"]],
        pageLength: 10
    }
    const _OrderInfo = ()=>{
        RedirectTo('/Order');
    }
    const _CreateQuotation = ()=>{
        RedirectTo('/Quotation/Create');
    }
    return (
        <>
        <React.Fragment>
            
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-8'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>รหัสลูกค้า :</span>
                                    </div>
                                    <div className='col-10'>
                                        <span className=''>00123411</span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>ชื่อลูกค้า :</span>
                                    </div>
                                    <div className='col-10'>
                                        <span className=''>คุณทดสอบ ทั่วไป</span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>เบอร์โทรติดต่อ :</span>
                                    </div>
                                    <div className='col-10'>
                                        <span className=''>0123456789</span>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>ประเภท :</span>
                                    </div>
                                    <div className='col-10'>
                                        <span className=''>หน่วยงาน</span>
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>ที่อยู่ :</span>
                                    </div>
                                    <div className='col-10'>
                                        <span className=''>เลขที่ 791/16 ตำบลพยอม อำเภอวังน้อย จังหวัดพระนครศรีอยุธยา</span>
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>บันทึก :</span>
                                    </div>
                                    <div className='col-10'>
                                        <textarea className='form-control' style={{'minHeight':'100px'}}/>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-2 '>
                                    </div>
                                    <div className='col-10 text-end'>
                                        <button type='button' className='btn btn-lg btn-save'>บันทึก</button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-12 text-center'>
                                                <div className='btn-group btn-group-lg'>
                                                <button type='button' className='btn bnt-lg btn-success' onClick={()=>{_CreateQuotation();}}>สร้างใบเสนอราคา</button>
                                                <button type='button' className='btn bnt-lg btn-warning text-white'>ประวัติการสั่งซื้อ</button>
                                                <button type='button' className='btn bnt-lg btn-danger text-white'>ติดต่อลูกค้า</button>
                                                </div>
                                            </div>
       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-lg-12'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <h5 className='fw-bold'>บันทึกภายใน</h5>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row mt-1 mb-1'>
                                            <div className='col-2 text-center'>
                                                <span>23/05/67 14:00</span>
                                            </div>
                                            <div className='col-10'>ลูกค้าสนใจบริการ บุพเฟ่ 10 ท่าน</div>
                                        </div>
                                        <div className='row mt-1 mb-1'>
                                            <div className='col-2 text-center'>
                                                <span>23/05/67 14:00</span>
                                            </div>
                                            <div className='col-10'>ลูกค้าสนใจบริการ บุพเฟ่ 10 ท่าน</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                    <div className='card'>
                            <div className='card-body' style={{'minHeight':'300px'}}>
                                <div className='row'>
                                    <div className="col-lg-12 table-responsive">
                                        <table id="HomeOrderView" className='table w-100' role='grid'>
                                            <thead>
                                                <tr>
                                                    <th className="text-center textcenter">เลขที่ใบเสนอราคา</th>
                                                    <th className="text-center textcenter">บริการ</th>
                                                    <th className="text-center textcenter">สถานที่</th>
                                                    <th className="text-center textcenter">วันที่</th>
                                                    <th className="text-center textcenter">สถานะ</th>
                                                    <th className="text-center no-sort"></th>
                                                </tr>
                                            </thead>
                                            <DataTable TableID={"HomeOrderView"} ref={ref} Configs={_configs}></DataTable>
                                            <tbody>
                                                <tr>
                                                    <td className="text-center textcenter">Q25040001</td>
                                                    <td className="text-center textcenter">[BHC1] พิธีสงฆ์ ชุดศิริสุข  จำนวน 50 ท่าน</td>
                                                    <td className="text-center textcenter">เลขที่ 791/16 ตำบลพยอม อำเภอวังน้อย จังหวัดพระนครศรีอยุธยา</td>
                                                    <td className="text-center textcenter">10/06/2567  เช้า</td>
                                                    <td className="text-center textcenter"><span className="badge bg-success">ชำระมัดจำ</span> </td>
                                                    <td className="text-center textcenter">
                                                        <div className='btn-group btn-group-sm'>
                                                            <button type='button' className='btn btn-info text-white' title="รายละเอียด" onClick={()=>{_OrderInfo()}}><FaInfo /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center textcenter">Q25040001</td>
                                                    <td className="text-center textcenter">[BHC1] พิธีสงฆ์ ชุดศิริสุข  จำนวน 50 ท่าน</td>
                                                    <td className="text-center textcenter">เลขที่ 791/16 ตำบลพยอม อำเภอวังน้อย จังหวัดพระนครศรีอยุธยา</td>
                                                    <td className="text-center textcenter">10/06/2567  เช้า</td>
                                                    <td className="text-center textcenter"><span className="badge bg-warning">เสนอราคา</span> </td>
                                                    <td className="text-center textcenter">
                                                        <div className='btn-group btn-group-sm'>
                                                            <button type='button' className='btn btn-info text-white' title="รายละเอียด" onClick={()=>{_OrderInfo()}}><FaInfo /></button>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="text-center textcenter">Q25040001</td>
                                                    <td className="text-center textcenter">[BHC1] พิธีสงฆ์ ชุดศิริสุข  จำนวน 50 ท่าน</td>
                                                    <td className="text-center textcenter">เลขที่ 791/16 ตำบลพยอม อำเภอวังน้อย จังหวัดพระนครศรีอยุธยา</td>
                                                    <td className="text-center textcenter">10/06/2567  เช้า</td>
                                                    <td className="text-center textcenter"><span className="badge bg-danger">ยกเลิก</span> </td>
                                                    <td className="text-center textcenter">
                                                        <div className='btn-group btn-group-sm'>
                                                            <button type='button' className='btn btn-info text-white' title="รายละเอียด" onClick={()=>{_OrderInfo()}}><FaInfo /></button>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="text-center textcenter">Q25040001</td>
                                                    <td className="text-center textcenter">[BHC1] พิธีสงฆ์ ชุดศิริสุข  จำนวน 50 ท่าน</td>
                                                    <td className="text-center textcenter">เลขที่ 791/16 ตำบลพยอม อำเภอวังน้อย จังหวัดพระนครศรีอยุธยา</td>
                                                    <td className="text-center textcenter">10/06/2567  เช้า</td>
                                                    <td className="text-center textcenter"><span className="badge bg-info">รอตัดสินใจ</span> </td>
                                                    <td className="text-center textcenter">
                                                        <div className='btn-group btn-group-sm'>
                                                            <button type='button' className='btn btn-info text-white' title="รายละเอียด" onClick={()=>{_OrderInfo()}}><FaInfo /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        </>
    )
}

export default React.memo(Customer)
