import React from "react";
import Header from "./Header";
import Footer from './Footer';

const Content =(props) =>{
    let _Login = true;

    if(window.location.pathname === '/' || window.location.pathname === '/Login'){
        _Login = false;
    }

    return (
        <React.Fragment>
            {(_Login? <Header/> : "")}
            <main role='main' className='flex-shrink-0 mt-2 mb-2'>
            {props.children}
            </main>
            {(_Login? <Footer/> : "")}

        </React.Fragment>
    )
}
export default React.memo(Content)