import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {PageLogin,PageHome,PageCustomer,PageOrderCard,QuotationCreate, PageQuotationCreate} from './Pages';
const Router =() =>{
    return (
        <Routes>
            <Route path="/Home" element={<PageHome />} />
            <Route path="/Login" element={<PageLogin />} />
            <Route path="/Customer" element={<PageCustomer />} />
            <Route path="/Order" element={<PageOrderCard />} />
            <Route path="/Quotation/Create" element={<PageQuotationCreate />} />
            <Route path="*" element={<PageLogin />} />
        </Routes>
    )
}

export default React.memo(Router)