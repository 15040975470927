import React from 'react';

import '../Assets/CSS/Horapa.css';

class Header extends React.Component{
    // eslint-disable-next-line
    constructor(props) {
        super(props);
        
    }
    _Home = ()=>{
        window.location.href = '/Home';
    }

    render() {

        return (
            <>
            <header>
                <nav className="navbar navbar-light header">
                    <div className="container-fluid">
                        <img className='navbar-brand' src={require('../Assets/Images/Logo/horapa-logo_whiteC.png')} alt='Logo'/>                    
                        <div className='' style={{'width':'350px'}}>
                            <div className='row'>
                                <div className='col-2'>
                                    <span className="navbar-toggler-icon" onClick={()=>{this._Home();}}></span>
                                </div>
                                <div className='col-7'>
                                    <div className='row'>
                                        <div className='col-12 text-end text-white'>นางสาวทดสอบ ทดสอบ</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 text-end  text-white'>พนักงานขาย</div>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <img src={require('../Assets/Images/Avatar/avatar.png')} className='avatar' alt='Avatar'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            
            </>
        )
    }
}
export default Header;

