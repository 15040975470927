import React,{useRef} from 'react';
import { HomeChart } from '../../Components/Chart';
import '../../Assets/CSS/Horapa.css';
import {DataTable} from '../../Components'
import { FaRegUser,FaInfo  } from "react-icons/fa";
import { MdKeyboardReturn } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const PageOrderCard =()=> {
    const RedirectTo = useNavigate()

    const ref = useRef();
    const _configs = {
        "order": [[3, "desc"]],
        pageLength: 10
    }

    const _BackStep = ()=>{
        RedirectTo(-1);
    }
    return (
        <>
        <React.Fragment>
            
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-8'>
                        <div className='card'>
                            <div className='card-header'>
                                <h5 className='fw-bold'>เลขที่ใบเสนอราคา : Q25040001</h5>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>เลขที่ใบเสนอราคา :</span>
                                    </div>
                                    <div className='col-10'>
                                        <span className=''>Q25040001</span>
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>สถานะ :</span>
                                    </div>
                                    <div className='col-10'>
                                        <span className="badge bg-success">ชำระมัดจำ</span>
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>บริการ :</span>
                                    </div>
                                    <div className='col-10'>
                                        <span className=''>[BHC1] พิธีสงฆ์ ชุดศิริสุข</span>
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>จำนวน :</span>
                                    </div>
                                    <div className='col-10'>
                                        <span className=''>50 ท่าน</span>
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>ประเภทงาน :</span>
                                    </div>
                                    <div className='col-10'>
                                        <span className=''>ทำบุญบ้าน</span>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>สถานที่จัดงาน :</span>
                                    </div>
                                    <div className='col-10'>
                                        <span className=''>เลขที่ 791/16 ตำบลพยอม อำเภอวังน้อย จังหวัดพระนครศรีอยุธยา</span>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>ช่วงเวลา :</span>
                                    </div>
                                    <div className='col-10'>
                                        <span className=''> 10/06/2567 เวลา 10.30</span>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>นัดเตรียมสถานที่ :</span>
                                    </div>
                                    <div className='col-10'>
                                    <span className=''> 10/06/2567 เวลา 07.30</span>
                                    </div>
                                </div>
                                

                                <div className='row mt-5'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>หมายเหตุ :</span>
                                    </div>
                                    <div className='col-10'>
                                        <textarea className='form-control' style={{'minHeight':'100px'}}/>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-2 '>
                                    </div>
                                    <div className='col-10 text-end'>
                                        <button type='button' className='btn btn-lg btn-save'>บันทึก</button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-12 text-center'>
                                                <div className='btn-group btn-group-lg'>
                                                    <button type='button' className='btn bnt-lg btn-success' style={{'minWidth':'120px'}}>ข้อมูลแผนที่</button>
                                                    <button type='button' className='btn bnt-lg btn-warning text-white' style={{'minWidth':'120px'}}>ประวัติการสั่งซื้อ</button>
                                                    <button type='button' className='btn bnt-lg btn-danger text-white' onClick={()=>{_BackStep();}}><MdKeyboardReturn /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-lg-12'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <h5 className='fw-bold'>ข้อมูลลูกค้า</h5>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-3 text-end'>
                                                <span className='fw-bold'>รหัสลูกค้า :</span>
                                            </div>
                                            <div className='col-9'>
                                                <span className=''>00123411</span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-3 text-end'>
                                                <span className='fw-bold'>ชื่อลูกค้า :</span>
                                            </div>
                                            <div className='col-9'>
                                                <span className=''>คุณทดสอบ ทั่วไป</span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-3 text-end'>
                                                <span className='fw-bold'>เบอร์โทรติดต่อ :</span>
                                            </div>
                                            <div className='col-9'>
                                                <span className=''>0123456789</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className='col-lg-12'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <h5 className='fw-bold'>รายการสรุป</h5>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-12 table-responsive'>
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center'>รายการ</th>
                                                            <th className='text-center'>ยอดเงิน</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className='text-left'>ราคาแพ็คเก็จ</td>
                                                            <td className='text-end'>13,999.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='text-left'>เพิ่มอาหาร</td>
                                                            <td className='text-end'>1,250.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='text-left'>ค่าบริการนอกเขต</td>
                                                            <td className='text-end'>800.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='text-left'>ค่าเดินาง</td>
                                                            <td className='text-end'>2,300.00</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-5'>
                        <div className='card'>
                            <div className='card-header'>
                                <h5 className='fw-bold'>รายการอาหาร</h5>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12 table-responsive'>
                                        <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-center'>รายการอาหาร</th>
                                                        <th className='text-center'>หมายเหตุ</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td className='text-left'>ปลาผัดซอลเปรี้ยวหวาน</td>
                                                    <td className='text-left'></td>
                                                </tr>
                                                <tr>
                                                    <td className='text-left'>ลาบหมู ผักสด</td>
                                                    <td className='text-left'></td>
                                                </tr>
                                                <tr>
                                                    <td className='text-left'>แกงเขียวหวานลูกชิ้นปลากรายยอดมะพร้อมขนมจีน</td>
                                                    <td className='text-left'></td>
                                                </tr>
                                                <tr>
                                                    <td className='text-left'>ข้าวผัดกุ้ง</td>
                                                    <td className='text-left'>เพิ่ม</td>
                                                </tr>
                                                <tr>
                                                    <td className='text-left'>ต้นอ่อนทานตะวันทอดกรอบ + น้ำจิ้มซีฟู๊ด</td>
                                                    <td className='text-left'>แถม</td>
                                                </tr>

                                                <tr>
                                                    <td className='text-left'>บัวลอยเผือกกระทิสด</td>
                                                    <td className='text-left'></td>
                                                </tr>
                                                <tr>
                                                    <td className='text-left'>ผลไม้</td>
                                                    <td className='text-left'></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='card'>
                            <div className='card-header'>
                                <h5 className='fw-bold'>รายละเอียดอุปกรณ์</h5>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12 table-responsive'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>อุปกรณ์</th>
                                                    <th className='text-center'>จำนวน</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className='text-left'>โต๊ะหมู่บูชาสีครีมทอง 8 ชิ้น</td>
                                                <td className='text-center'>8</td>
                                            </tr>
                                            <tr>
                                                <td className='text-left'>ชุดอาสนะเก้าอี้สีเหลืองทอง / ผู้ปูทับอาสนะ</td>
                                                <td className='text-center'>9</td>
                                            </tr>
                                            <tr>
                                                <td className='text-left'>โต๊ะ</td>
                                                <td className='text-center'>5 ชุด</td>
                                            </tr>
                                            <tr>
                                                <td className='text-left'>เก้าอี้</td>
                                                <td className='text-center'>5 ชุด</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        </>
    )
}

export default React.memo(PageOrderCard)
