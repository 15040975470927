import React,{useRef} from 'react';
import { HomeChart } from '../../Components/Chart';
import '../../Assets/CSS/Horapa.css';
import {DataTable} from '../../Components'
import { FaRegUser,FaInfo  } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const Home =()=> {
    const RedirectTo = useNavigate()

    const ref = useRef();
    const _configs = {
        "order": [[3, "desc"]],
        pageLength: 10
    }
    const _OrderInfo = ()=>{
        RedirectTo('/Order');
    }
    const _CustomerInfo = ()=>{
        RedirectTo('/Customer');
    }
    return (
        <>
        <React.Fragment>
            
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-8'>
                        <div className='card'>
                            <div className='card-body' style={{'minHeight':'300px'}}>

                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='card'>
                            <div className='card-body' style={{'minHeight':'300px'}}>
                                <HomeChart />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                    <div className='card'>
                            <div className='card-body' style={{'minHeight':'300px'}}>
                                <div className='row'>
                                    <div className="col-lg-12 table-responsive">
                                        <table id="HomeOrderView" className='table w-100' role='grid'>
                                            <thead>
                                                <tr>
                                                    <th className="text-center textcenter">เลขที่ใบเสนอราคา</th>
                                                    <th className="text-center textcenter">ลูกค้า</th>
                                                    <th className="text-center textcenter">บริการ</th>
                                                    <th className="text-center textcenter">สถานที่</th>
                                                    <th className="text-center textcenter">วันที่</th>
                                                    <th className="text-center textcenter">สถานะ</th>
                                                    <th className="text-center textcenter">พนักงานขาย</th>
                                                    <th className="text-center no-sort"></th>
                                                </tr>
                                            </thead>
                                            <DataTable TableID={"HomeOrderView"} ref={ref} Configs={_configs}></DataTable>
                                            <tbody>
                                                <tr>
                                                    <td className="text-center textcenter">Q25040001</td>
                                                    <td className="text-center textcenter">คุณทดสอบ ทั่วไป</td>
                                                    <td className="text-center textcenter">[BHC1] พิธีสงฆ์ ชุดศิริสุข  จำนวน 50 ท่าน</td>
                                                    <td className="text-center textcenter">ตำบลพยอม อำเภอวังน้อย พระนครศรีอยุธยา</td>
                                                    <td className="text-center textcenter">10/06/2567  เช้า</td>
                                                    <td className="text-center textcenter"><span className="badge bg-success">ชำระมัดจำ</span> </td>
                                                    <td className="text-center textcenter">ทีมขาย 1</td>
                                                    <td className="text-center textcenter">
                                                        <div className='btn-group btn-group-sm'>
                                                            <button type='button' className='btn btn-info text-white' title="รายละเอียด" onClick={()=>{_OrderInfo()}}><FaInfo /></button>
                                                            <button type='button' className='btn btn-secondary text-white' title="ข้อมูลลูกค้า" onClick={()=>{_CustomerInfo()}}><FaRegUser /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center textcenter">Q25040001</td>
                                                    <td className="text-center textcenter">คุณทดสอบ ทั่วไป</td>
                                                    <td className="text-center textcenter">[BHC1] พิธีสงฆ์ ชุดศิริสุข  จำนวน 50 ท่าน</td>
                                                    <td className="text-center textcenter">ตำบลพยอม อำเภอวังน้อย พระนครศรีอยุธยา</td>
                                                    <td className="text-center textcenter">10/06/2567  เช้า</td>
                                                    <td className="text-center textcenter"><span className="badge bg-warning">เสนอราคา</span> </td>
                                                    <td className="text-center textcenter">ทีมขาย 1</td>
                                                    <td className="text-center textcenter">
                                                        <div className='btn-group btn-group-sm'>
                                                            <button type='button' className='btn btn-info text-white' title="รายละเอียด" onClick={()=>{_OrderInfo()}}><FaInfo /></button>
                                                            <button type='button' className='btn btn-secondary text-white' title="ข้อมูลลูกค้า" onClick={()=>{_CustomerInfo()}}><FaRegUser /></button>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="text-center textcenter">Q25040001</td>
                                                    <td className="text-center textcenter">คุณทดสอบ ทั่วไป</td>
                                                    <td className="text-center textcenter">[BHC1] พิธีสงฆ์ ชุดศิริสุข  จำนวน 50 ท่าน</td>
                                                    <td className="text-center textcenter">ตำบลพยอม อำเภอวังน้อย พระนครศรีอยุธยา</td>
                                                    <td className="text-center textcenter">10/06/2567  เช้า</td>
                                                    <td className="text-center textcenter"><span className="badge bg-danger">ยกเลิก</span> </td>
                                                    <td className="text-center textcenter">ทีมขาย 1</td>
                                                    <td className="text-center textcenter">
                                                        <div className='btn-group btn-group-sm'>
                                                            <button type='button' className='btn btn-info text-white' title="รายละเอียด" onClick={()=>{_OrderInfo()}}><FaInfo /></button>
                                                            <button type='button' className='btn btn-secondary text-white' title="ข้อมูลลูกค้า" onClick={()=>{_CustomerInfo()}}><FaRegUser /></button>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="text-center textcenter">Q25040001</td>
                                                    <td className="text-center textcenter">คุณทดสอบ ทั่วไป</td>
                                                    <td className="text-center textcenter">[BHC1] พิธีสงฆ์ ชุดศิริสุข  จำนวน 50 ท่าน</td>
                                                    <td className="text-center textcenter">ตำบลพยอม อำเภอวังน้อย พระนครศรีอยุธยา</td>
                                                    <td className="text-center textcenter">10/06/2567  เช้า</td>
                                                    <td className="text-center textcenter"><span className="badge bg-info">รอตัดสินใจ</span> </td>
                                                    <td className="text-center textcenter">ทีมขาย 1</td>
                                                    <td className="text-center textcenter">
                                                        <div className='btn-group btn-group-sm'>
                                                            <button type='button' className='btn btn-info text-white' title="รายละเอียด" onClick={()=>{_OrderInfo()}}><FaInfo /></button>
                                                            <button type='button' className='btn btn-secondary text-white' title="ข้อมูลลูกค้า" onClick={()=>{_CustomerInfo()}}><FaRegUser /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        </>
    )
}

export default React.memo(Home)
